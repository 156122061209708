<script>
import { Pie, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {

    // extend
	extends: Pie,

    // define props
    mixins: [reactiveProp],

    // set fixed options for this component
	methods: {
		options(){
			return {
				responsive: true,
				maintainAspectRatio: true,
				tooltips: {
					enabled: false
				},
				animation: {
					duration: 1000
				},
				legend: {
					onClick: function() { return; },
					position: 'bottom',
					labels: {
						boxWidth: 16,
						fontColor: '#888',
						fontSize: 13
					}
				},
			};
		}
	},

	// init render
	mounted() {
        this.renderChart(this.chartData, this.options());
	},

}
</script>