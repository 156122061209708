<template>
    <div 
        class="embed-responsive embed-responsive-16by9 minilisting-image lazy"
        v-lazy:background-image="src"
    >
    <span class="sr-only" >{{ alt }}</span>
    </div>
</template>
<script>
export default {
    props: ['src', 'alt', 'link', 'address'],
}
</script>


