<template>
    <div >
        <div>
            <label>Property Status</label>
        </div>
        <div class="d-block p-3 border rounded" >
            <label class="custom-control custom-checkbox">
                <input type="checkbox" name="status[]" value="Active" class="custom-control-input" v-model="status.active">
                <span class="custom-control-label">Active</span>
            </label>
            <label class="custom-control custom-checkbox">
                <input type="checkbox" name="status[]" value="Contingent" class="custom-control-input" v-model="status.undercontract">
                <span class="custom-control-label">Taking Backup Offers</span>
            </label>
            <label class="custom-control custom-checkbox">
                <input type="checkbox" name="status[]" value="Pending" class="custom-control-input" v-model="status.pending">
                <span class="custom-control-label">Pending</span>
            </label>
            <label class="custom-control custom-checkbox">
                <input type="checkbox" name="status[]" value="Sold" class="custom-control-input" v-model="status.sold">
                <span class="custom-control-label">Sold</span>
            </label>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            fieldValue: {
                type: Array,
                default: () => []
            }
        },

        data () {
            return {
                status: {
                    active: true,
                    sold: false,
                    undercontract: false,
                    pending: true
                },
                value: []
            }
        },

        watch: {
            status: {
                deep: true,
                handler(val){

                    this.value = []

                    if(val.active == true){
                        this.value.push('Ative')
                    }
                    if(val.sold == true){
                        this.value.push('Sold')
                    }
                    if(val.undercontract == true){
                        this.value.push('Contingent')
                    }
                    if(val.pending == true){
                        this.value.push('Pending')
                    }

                    this.$emit('updated', {
                        property: 'status',
                        value: this.value
                    })
                }
            }
        },

        mounted () {
            this.status.active = this.fieldValue.includes('Active')
            this.status.sold = this.fieldValue.includes('Sold')
            this.status.undercontract = this.fieldValue.includes('Contingent')
            this.status.pending = this.fieldValue.includes('Pending')
        }
    }
</script>
