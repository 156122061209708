<template>
    <div>
        <div v-if="success == true" id="success-content">
            <slot></slot>
        </div>
        <form v-if="!success">
            <div class="row mt-1">
                <div class="col-lg-4" >
                    <div class="form-group mb-4">
                        <label class="sr-only" for="name-field">Name</label>

                        <div v-if="form.name == ''">
                            <p class="alert alert-danger m-0" v-if="hasError == true">This field is required.</p>
                        </div>

                        <input
                            type="text"
                            id="name-field"
                            class="form-control rounded-pill"
                            :class="{'border-danger': hasError && form.name == '' }"
                            placeholder="Name"
                            v-model="data.name"
                            required
                        >
                    </div>
                </div>
                <div class="col-lg-4" >
                    <div class="form-group mb-4">
                        <label class="sr-only" for="email-field">Email Address</label>

                        <div v-if="form.email == ''">
                            <p class="alert alert-danger m-0" v-if="hasError == true">This field is required.</p>
                        </div>

                        <input
                            type="email"
                            id="email-field"
                            class="form-control rounded-pill"
                            :class="{'border-danger': hasError && form.email == '' }"
                            placeholder="Email Address"
                            v-model="data.email"
                            required
                        >
                    </div>
                </div>
                <div class="col-lg-4" >
                    <div class="form-group mb-4">
                        <label class="sr-only" for="phone-number-field">Phone Number</label>

                        <div v-if="form.phone == ''">
                            <p class="alert alert-danger m-0" v-if="hasError == true">This field is required.</p>
                        </div>

                        <input
                            type="tel"
                            id="phone-number-field"
                            class="form-control rounded-pill"
                            :class="{'border-danger': hasError && form.phone == '' }"
                            placeholder="Phone Number"
                            v-model="data.phone"
                            required
                        >
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label class="sr-only" for="message-field">Message</label>

                <div v-if="form.comments == ''">
                    <p class="alert alert-danger m-0" v-if="hasError == true">This field is required.</p>
                </div>

                <textarea
                    id="message-field"
                    class="form-control py-3"
                    :class="{'border-danger': hasError && form.comments == '' }"
                    placeholder="Message"
                    v-model="data.comments"
                    style="min-height: 170px;"
                    required
                >
                </textarea>
            </div>
            <div class="pt-4 text-left">
                <invisible-recaptcha
                    :sitekey="sitekey"
                    :validate="validate"
                    :callback="submitForm"
                    :disabled="processing"
                    class="btn btn-light rounded-pill"
                    type="submit"
                    id="contact-form-submit-button" >
                    Send Message
                </invisible-recaptcha>
                <div
                    v-if="!processing"
                    class="p-3 font-weight-bold font-italic d-inline-block"
                    style="font-size:.9em;">
                    All&nbsp;fields&nbsp;required.</div>
                <div
                    v-if="processing"
                    class="p-3 font-italic d-inline-block"
                    style="font-size:.9em;"
                    >Sending&nbsp;message.</div>
            </div>
        </form>
    </div>
</template>
<script>
import ContactForm from '../models/contact-form.js'
import invisibleRecaptcha from './InvisibleRecaptcha'
    export default {

        components: {
            'invisible-recaptcha': invisibleRecaptcha
        },

        props: {
            sitekey: {
                type: String,
                default: ''
            },
            mlsNumber: {
                type: String,
                default: 0
            },
            formId: {
                type: String,
                default: ''
            },
            postUrl: {
                type: String,
                default: '/wp-json/kerigansolutions/v1/submit-contact-form'
            }
        },

        data () {
            return {
                processing: false,
                data: {
                    name: '',
                    email: '',
                    phone: '',
                    comments: '',
                    token: '',
                    form: "",
                    mls: '',
                },
                form: new ContactForm({
                    data: null,
                    url: ''
                })
            }
        },

        watch: {
            hasError: function (newVal, oldVal) {
                if(newVal == true){
                    this.processing = false;
                }
            },
            data: {
                deep: true,
                handler(val){
                    this.form.data = val
                }
            }
        },

        computed: {
            hasError: function() {
                return this.form.hasError;
            },
            errorCode: function () {
                return this.form.errorCode;
            },
            success: function () {
                return this.form.success;
            }
        },

        mounted() {
            this.data.mls = this.mlsNumber;
            this.data.form = this.formId;
            this.form.url = this.postUrl;
        },

        methods: {
            submitForm (recaptchaToken) {
                this.data.token = recaptchaToken

                this.form.submit().then(() => {
                    this.processing = false;
                }).catch(err => {
                    this.processing = false;
                });
            },
            validate () {
                this.form.hasError = false;
                this.processing = true;

                return this.name != '' && this.phone != '';
            }
        }
    }
</script>
<style lang="scss" scoped>
#message-field {
    border-radius: 1rem;
}
</style>
