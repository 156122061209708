<template>
    <div id="calculator" class="row py-4">

        <!-- inputs -->
        <div id="inputs" class="col-lg-4 border-right my-4">
            <form class="form px-4" >
                
                <label for="price">Home price</label>
                <div class="input-group mb-3">
                    <input id="price" type="text" class="form-control rounded-pill" v-model="price" @keyup="checkPrice" @blur="onBlurPrice" >
                </div>
                
                <label for="upfront">Down payment ({{ upfront }}%)</label>
                <div class="input-group mb-3">
                    <input id="upfront" class="w-100" type="range" min="0" max="100" step="10" v-model="upfront" >
                </div>
                
                <label for="rate">Interest rate</label>
                <div class="input-group d-flex rounded-pill mb-3">
                    
                    <input id="rate" type="text" class="form-control flex-grow-1 rounded-left" v-model.lazy="rate" @keyup.enter="checkRate" @blur="checkRate" >
                    <div class="input-group-append w-auto p-1 border px-3 rounded-right">
                        <div class="input-group-text">%</div>
                    </div>
                </div>
                <div id="term" class="input-group mb-3">
                    <label for="term">Loan term</label>
                    <div>
                        <select v-model="term" class="custom-select rounded-pill" >
                            <option v-for="term in terms" :key="term.index" :value="term.value">{{ term.text }}</option>
                        </select>
                    </div>
                </div>
            </form>
        </div><!-- #inputs -->

        <!-- results -->
        <div id="results"  class="col-lg-8 row align-items-center py-4">
            <div class="col-md-6">
                <ul id="details">
                    <li>
                        <h5>Monthly payment*</h5>
                        <div v-html="toCurrency(payment)"></div>
                    </li>
                    <li>
                        <h5>Total payments</h5>
                        <div v-if="priceInt">{{ months }} monthly payments</div>
                        <div v-else>&ndash;&ndash;</div>
                    </li>
                    <li>
                        <h5>Down payment ({{ upfront }}%)</h5>
                        <div v-html="toCurrency(down, 0)"></div>
                    </li>
                    <li>
                        <h5>Principal</h5>
                        <div v-html="toCurrency(loan, 0)"></div>
                    </li>
                    <li>
                        <h5>Total interest paid</h5>
                        <div v-html="toCurrency(interest, 0)"></div>
                    </li>
                    <li>
                        <h5>Total mortgage cost*</h5>
                        <div v-html="toCurrency(total, 0)"></div>
                    </li>
                </ul>
            </div>
            <div id="chart" class="col-md-6">
                <div><pie-chart :chartData="chartData" :animate="animate"></pie-chart></div>
            </div>
            <div class="col-12 text-center">*Estimated, not including taxes and insurance.</div>
        </div><!-- #results -->

    </div>
</template>
<script>
require('es6-promise').polyfill();
import axios from 'axios'
import PieChart from './PieChart'

export default {
    // define data props with default values

    components: {
        'pie-chart': PieChart
    },

    props: {
        amount: {
            type: Number,
            default: 0
        },
        intRate: {
            type: Number,
            default: 3
        }
    },

	data () {
        return {
            price: 0,
            upfront: 20,
            rate: 3,
            term: 30,
            terms: [
                { text: '15 year fixed', value: 15 },
                { text: '20 year fixed', value: 20 },
                { text: '30 year fixed', value: 30 }
            ],
            animate: false,
        }
    },

	// methods
	methods: {

		// format value to currency
		toCurrency(value, cents = 2) {

			// if invalid, return two en dashes
			if (isNaN(value) || !isFinite(value)) return '&ndash;&ndash;';

			// else make it pretty and return
			return '$' + value.toFixed(cents).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

		},

		// validate the rate
		checkRate(){

			// remove non numeric chars (except decimal)
			var value = String(this.rate).replace(/[^0-9.]/g, '');

			// if value is empty or invalid, set to 0
			if (value == '' || isNaN(value)) value = '0';

			// if first char is decimal, add a leading zero
			if (value.charAt(0) == '.') value = '0' + value;

			// update the value (max is 100)
			this.rate = (value < 100) ? value : '100';

		},

		// validate the price
		checkPrice() {

			// remove leading zeros and non numeric chars (except decimal)
			var value = String(this.price).replace(/[^0-9.]/g, '').replace(/^0+/, '');

			// limit to no more than 2 decimal places
			if (value.includes('.')) {
				if (value.split('.')[1].length > 2) value = value.slice(0, -1);
			}

			// if the value is invalid, just display dollar sign and return
			if (value == '' || isNaN(value)) {
				this.price = '$';
				return;
			}

			// else make it pretty
			this.price = '$' + String(value).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

		},

		// check price when the field loses focus
		onBlurPrice(){
			// if value is less than two chars, set it to '$0'
			if (this.price.length < 2) this.price = '$0';
		},
        
	},

	// calculations
	computed: {

		// price as int (remove currency formatting)
		priceInt: function(){
			var price = parseFloat(String(this.price).replace(/[^0-9.]/g, ''));
			return (isNaN(price)) ? 0 : price;
		},

		// down payment = price * (upfront / 100)
		down: function(){
			return this.priceInt * (parseInt(this.upfront) / 100);
		},

		// loan amount = price - down payment
		loan: function(){
			return this.priceInt - this.down;
		},

		// months = term * 12
		months: function(){
			return this.term * 12;
		},

		// monthly payment calc
		payment: function(){
			var P = this.loan;
			var r = parseFloat(this.rate / 1200);
			var N = parseInt(this.months);
			return (r == 0) ? P / N : P * (r * Math.pow((1 + r), N)) / (Math.pow((1 + r), N) - 1);
		},

		// total mortgage cost = months * monthly payment + down payment
		total: function(){
			return this.months * this.payment + this.down;
		},

		// total interest payable = total cost - down payment - loan amount
		interest: function(){
			return this.total - this.down - this.loan;
		},

		// define chart data
		chartData: function(){

			// if price is zero
			if (this.priceInt == 0) {
				var data   = [100,0,0];
				var colors = '#f0f0f0';
			}

			// else if price > zero
			else {
				var data   = [this.down, this.loan, this.interest];
				var colors = ['#3D3E8E','#77A5CE','#B3B76A'];
			}

			// return chart data
			return {
				labels: ["Down payment","Principal","Interest"],
				datasets: [{
					backgroundColor: colors,
					borderColor: "#fff",
					borderWidth: 4,
					hoverBackgroundColor: colors,
					hoverBorderColor: "#fff",
					hoverBorderWidth: 4,
					data: data
				}]
			};

		},

	},

	// once created
	created(){
        this.price = this.amount
        this.rate = this.intRate
	}
}
</script>
<style lang="scss" scoped>
#inputs {
    label {
        font-weight: 600;
    }

    .rounded-left {
        border-radius: 2rem 0 0 2rem !important;
        padding-left: 1rem;
    }

    .rounded-right {
        border-radius: 0 2rem 2rem 0 !important;
        padding-right: .75rem;
        background-color: #eee;
        border-color: #aaa;
        border-left: none;
        font-weight: 600;
    }
}

#details {
    list-style: none;

    li {
        border-bottom: 1px solid #ddd;
        padding: .5rem 0 .25rem;

        &:last-child {
            border: none;
        }

        h5 {
            font-weight: 600;
            margin-bottom: 0;
            margin-bottom: .25rem;
        }
    }
}
</style>