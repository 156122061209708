<template>
    <div>
        <div v-if="success == true" id="success-content">
            <slot></slot>
        </div>
        <form v-if="!success">
            <div class="row mt-1">

                <div class="col-lg-6" >
                    <div class="form-group mb-4">
                        <label class="sr-only" for="name-field">Name *</label>

                        <input
                            type="text"
                            id="name-field"
                            class="form-control rounded-pill"
                            :class="{'border-danger': hasError && form.name == '' }"
                            placeholder="Name *"
                            v-model="data.name"
                            required
                        >
                        <div v-if="form.name == '' && hasError == true">
                            <p class="text-danger mb-0 mt-2" >This field is required.</p>
                        </div>

                    </div>
                </div>

                <div class="col-lg-6" >
                    <div class="form-group mb-4">
                        <label class="sr-only" for="phone-number-field">Phone Number *</label>

                        <input
                            type="tel"
                            id="phone-number-field"
                            class="form-control rounded-pill"
                            :class="{'border-danger': hasError && form.phone == '' }"
                            placeholder="Phone Number *"
                            v-model="data.phone"
                            required
                        >
                        <div v-if="form.phone == '' && hasError == true">
                            <p class="text-danger mb-0 mt-2" >This field is required.</p>
                        </div>

                    </div>
                </div>

                <div class="col-lg-12" >
                    <div class="form-group mb-4">
                        <label class="sr-only" for="email-field">Email Address</label>

                        <input
                            type="email"
                            id="email-field"
                            class="form-control rounded-pill"
                            placeholder="Email Address"
                            v-model="data.email"
                        >
                    </div>
                </div>

                <div class="col-lg-12" >
                    <div class="form-group mb-4">
                        <label class="d-block pr-4 d-md-inline-block">Are you prequalified?</label>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="prequalified-yes" value="Yes" v-model="data.prequalified" class="custom-control-input">
                            <label class="custom-control-label" for="prequalified-yes">Yes</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="prequalified-no" value="No" v-model="data.prequalified" class="custom-control-input">
                            <label class="custom-control-label" for="prequalified-no" >No</label>
                        </div>
                    </div>
                </div>
                
            </div>

            <div class="pt-4">                
                <invisible-recaptcha 
                    :sitekey="sitekey" 
                    :validate="validate"
                    :callback="submitForm"
                    :disabled="processing"
                    class="btn btn-secondary rounded-pill px-xl-5 pointer" 
                    type="submit" 
                    id="contact-form-submit-button" >
                    Submit
                </invisible-recaptcha>
                <div 
                    v-if="!processing"
                    class="p-3 font-weight-bold font-italic d-inline-block" 
                    style="font-size:.9em;">
                    *&nbsp;=&nbsp;required.</div>
                <div
                    v-if="processing"
                    class="p-3 font-italic d-inline-block" 
                    style="font-size:.9em;" 
                    >Sending&nbsp;request.</div>
            </div>
        </form>
    </div>
</template>
<script>
import ContactForm from '../models/contact-form.js' 
import invisibleRecaptcha from './InvisibleRecaptcha'
    export default {

        components: {
            'invisible-recaptcha': invisibleRecaptcha
        },

        props: {
            sitekey: {
                type: String,
                default: ''
            },
            download: {
                type: String,
                default: ''
            }
        },

        data () {
            return {
                processing: false,
                data: {
                    name: '',
                    email: '',
                    phone: '',
                    comments: '',
                    token: '',
                    download: '',
                    form: "Buyer's Guide",
                    prequalified: 'No',
                },
                form: new ContactForm({
                    data: null,
                    url: '/wp-json/kerigansolutions/v1/submit-registration-form'
                })
            }
        },

        watch: {
            hasError: function (newVal, oldVal) {
                if(newVal == true){
                    this.processing = false;
                }
            },
            data: {
                deep: true,
                handler(val){
                    this.form.data = val
                }
            }
        },

        computed: {
            hasError: function() {
                return this.form.hasError;
            },
            errorCode: function () {
                return this.form.errorCode;
            },
            success: function () {
                return this.form.success;
            }
        },

        mounted() {
            this.data.download = this.download;
        },

        methods: {
            submitForm (recaptchaToken) {
                this.data.token = recaptchaToken
                
                this.form.submit().then(() => {
                    this.processing = false;
                }).catch(err => {
                    this.processing = false;
                });
            },
            validate () {
                this.form.hasError = false;
                this.processing = true;

                return 
                    this.name != '' &&
                    this.phone != '';
            }
        }
    }
</script>
<style lang="scss" scoped>
#message-field {
    border-radius: 1rem;
}
</style>