<template>
    <div>
        <form action="/property-search/" method="GET" >
        <input type="hidden" name="q" value="search" >
            <div class="row no-gutters" >
                <div class="col-12" >
                    <label class="sr-only" for="omni-field">City, Subdivision, MLS# or Zip Code</label>
                    <smart-field
						v-bind:fieldValue="formData.omni"
						@updated="updateValue"
						preview="City, Subdivision, MLS# or Zip Code"
						class="my-2"
                        id="omni-field"
					></smart-field>
                </div>
                <div class="col-12 col-md-auto flex-grow-1 pr-1" >
                    <label class="sr-only" for="proptype-field">Property Type</label>
                    <property-type
                        class="my-2"
                        id="proptype-field"
                        v-bind:fieldValue="formData.property_type"
                        @updated="updateValue"
                        preview="Property Type"
                    >
                    </property-type>
                </div>
                <div class="col-6 col-md-auto px-1">
                    <label class="sr-only" for="min-price-field">Min Price</label>
                    <min-price-field
                        class="my-2"
                        v-bind:fieldValue="formData.minPrice"
                        @updated="updateValue"
                        preview="Min Price"
                    >
                    </min-price-field>
                </div>
                <div class="col-6 col-md-auto pl-1">
                    <label class="sr-only" for="max-price-field">Max Price</label>
                    <max-price-field
                        class="my-2"
                        v-bind:fieldValue="formData.maxPrice"
                        @updated="updateValue"
                        preview="Max Price"
                    ></max-price-field>
                </div>
                <div class="col-12 pt-2 pt-md-3 text-center text-md-left">
                    <button type="submit" class="btn btn-secondary rounded-pill px-4">Property Search</button>
                </div>
            </div>


        </form>
    </div>
</template>
<script>
import SmartField from './fields/SmartField.vue'
import MaxPrice from './fields/MaxPrice.vue'
import MinPrice from './fields/MinPrice.vue'
import PropertyType from './fields/PropertyType.vue'

    export default {

        components: {
            'smart-field': SmartField,
            'property-type': PropertyType,
            'min-price-field': MinPrice,
            'max-price-field': MaxPrice,
        },

        data() {
            return {
                formData: {
                    omni: '',
                    property_type: '',
                    minPrice: '',
                    maxPrice: ''
                },
            }
        },

        created() {
            let urlParams = new URLSearchParams(window.location.search)
            this.formData.omni = urlParams.get('omni') ?? ""
            this.formData.property_type = urlParams.get('property_type') ?? ""
            this.formData.minPrice = urlParams.get('minPrice') ?? ""
            this.formData.maxPrice = urlParams.get('maxPrice') ?? ""
        },

        methods: {
            updateValue(data){
                this.formData[data.property] = data.value
            }
        }

}
</script>
