import axios from 'axios';

export default class ContactForm {

    constructor (data) {
        for (let field in data.data) {
            this.data[field] = data[field];
        }

        this.url          = data.url;
        this.hasError     = false;
        this.errorMessage = "";
        this.errorCode    = "";
        this.success      = false;
    }
    submit () {
        axios.post(this.url, this.data).then(() => {
            this.success = true;
            this.clearForm();

        }).catch(err => {
            this.hasError     = true;
            this.errorMessage = err.response.data.message;
            this.errorCode    = err.response.data.code;
        });
    }
    clearForm() {
        for (let field in this.data) {
            this.data[field] = "";
        }
    }
}
