var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slider-container bg-black"},[_c('div',{staticClass:"slider-overlay-content"},[_vm._t("default")],2),_vm._v(" "),_c('div',{staticClass:"slider"},[_c('div',{staticClass:"slides d-lg-none",on:{"mouseleave":_vm.unpauseSlide}},_vm._l((_vm.sliderData),function(slide,index){return _c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(slide.smartphone_photo.url),expression:"slide.smartphone_photo.url",arg:"background-image"}],key:index,staticClass:"slide",class:{
                    'active': index == _vm.activeSlide
                },style:({ 
                    'background-position': slide.smartphone_image_alignment,
                    'background-size': slide.smartphone_image_fill_type,
                    'z-index': slide.index
                })},[_c('div',{staticClass:"container p-0 d-flex h-100 text-white",class:slide.smartphone_content_alignment},[_c('div',{staticClass:"slide-content p-4 w-100 d-flex flex-column",class:slide.smartphone_content_justification,domProps:{"innerHTML":_vm._s(slide.content)}})])])}),0),_vm._v(" "),_c('div',{staticClass:"slides d-none d-lg-flex",on:{"mouseleave":_vm.unpauseSlide}},_vm._l((_vm.sliderData),function(slide,index){return _c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(slide.photo.url),expression:"slide.photo.url",arg:"background-image"}],key:index,staticClass:"slide",class:{
                    'active': index == _vm.activeSlide
                },style:({ 
                    'background-position': slide.image_alignment,
                    'background-size': slide.image_fill_type
                })},[_c('div',{staticClass:"container p-0 d-flex h-100 text-white",class:slide.content_alignment + ' ' + slide.content_justification},[_c('div',{staticClass:"col-md-8 col-lg-6 slide-content py-4 py-lg-5",domProps:{"innerHTML":_vm._s(slide.content)}})])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }