<template>
    <div>
        <div>
            <label>Features</label>
        </div>
        <div class="d-block p-3 border rounded" >
            <label class="custom-control custom-checkbox">
                <input type="checkbox" name="open_houses" class="custom-control-input" v-model="open_houses">
                <span class="custom-control-label">Open Houses</span>
            </label>
            <label class="custom-control custom-checkbox">
                <input type="checkbox" name="is_waterfront" class="custom-control-input" v-model="is_waterfront">
                <span class="custom-control-label">Waterfront</span>
            </label>
            <label class="custom-control custom-checkbox">
                <input type="checkbox" name="is_waterview" class="custom-control-input" v-model="is_waterview">
                <span class="custom-control-label">Waterview</span>
            </label>
            <label class="custom-control custom-checkbox">
                <input type="checkbox" name="pool" class="custom-control-input" v-model="pool">
                <span class="custom-control-label">Pool</span>
            </label>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
           fieldValue: {
                type: Object,
                default: () => {}
            }
        },

        data () {
            return {
                open_houses: false,
                is_waterfront: false,
                is_waterview: false,
                pool: false
            }
        },

        watch: {
            open_houses(val){
                this.$emit('updated', {
                    property: 'open_houses',
                    value: val
                })
            },
            is_waterfront(val){
                this.$emit('updated', {
                    property: 'is_waterfront',
                    value: val
                })
            },
            is_waterview(val){
                this.$emit('updated', {
                    property: 'is_waterview',
                    value: val
                })
            },
            pool(val){
                this.$emit('updated', {
                    property: 'pool',
                    value: val
                })
            }
        },

        mounted () {
            this.open_houses = this.fieldValue.open_houses
            this.is_waterfront = this.fieldValue.is_waterfront
            this.is_waterview = this.fieldValue.is_waterview
            this.pool = this.fieldValue.pool
        }
    }
</script>
