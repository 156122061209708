window.Vue = require('vue')
Vue.config.devtools = false

// import VueParallaxJs from 'vue-parallax-js'
// Vue.use(VueParallaxJs)

import VueLazyload from 'vue-lazyload';
Vue.use(VueLazyload, {
    lazyComponent: true,
    preLoad: 1.1,
    error: '',
    loading: '',
    attempt: 1
})

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

import vueScrollto from 'vue-scrollto'
Vue.use(vueScrollto, {
    container: "body",
    duration: 1000,
    easing: 'ease-out',
    offset: -100,
    force: false,
    cancelable: false,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
})

import PortalVue from 'portal-vue'
Vue.use(PortalVue)

import SocialIcons from './components/SocialMediaIcons'
import Slider from './components/Slider'
import MobileMenu from './components/MobileNavMenu'
import Recaptcha from './components/InvisibleRecaptcha'
import ContactForm from './components/ContactForm'
import BuyersForm from './components/BuyersForm'
import SellersForm from './components/SellersForm'
import SearchBar from './components/SearchBar'
import SortForm from './components/SortForm'
import QuickSearch from './components/QuickSearch'
import ListingPhoto from './components/ListingPhoto'
import SocialSharingIcons from './components/SocialSharingIcons'
import PhotoGallery from './components/PhotoGallery'
import MortgageCalculator from './components/MortgageCalculator'
import FavoriteProperties from './components/FavoriteProperties'
import FavoriteButton from './components/FavoriteButton'

const app = new Vue({
    el: '#app',

    components: {
        'social-icons': SocialIcons,
        'kma-slider': Slider,
        'mobile-menu': MobileMenu,
        'invisible-recaptcha': Recaptcha,
        'contact-form': ContactForm,
        'buyers-form': BuyersForm,
        'sellers-form': SellersForm,
        'search-bar': SearchBar,
        'sort-form': SortForm,
        'quick-search': QuickSearch,
        'listing-photo': ListingPhoto,
        'social-sharing-icons': SocialSharingIcons,
        'photo-gallery': PhotoGallery,
        'mortgage-calculator': MortgageCalculator,
        'favorites': FavoriteProperties,
        'fav-button': FavoriteButton
    },

    data: {
        clientHeight: 0,
        windowHeight: 0,
        windowWidth: 0,
        isScrolling: false,
        scrollPosition: 0,
        footerStuck: false,
        mounted: false,
        mobileMenuOpen: false,
        ConnectOpen: false,
        galleryIsOpen: false
    },

    methods: {
        handleScroll() {
            this.scrollPosition = (window.pageYOffset != undefined ? window.pageYOffset : window.scrollY);

            if (!this.mobileMenuOpen) {
                this.isScrolling = (this.scrollPosition > 0)
            }
        },

        handleResize() {
            this.windowHeight = window.innerHeight;
            this.windowWidth = window.innerWidth;
            this.footerStuck = window.innerHeight > this.$root.$el.clientHeight;
            this.clientHeight = this.$root.$el.clientHeight;
        },

        toggleMobileMenu() {
            this.mobileMenuOpen = !this.mobileMenuOpen;
        },

        openGallery() {
            this.galleryIsOpen = true;
        },

        closeGallery() {
            this.galleryIsOpen = false;
        },

        handleFavorite(data) {
            this.$refs.favorites.handle(data)
        },

        toClipboard(id) {
            var copyText = document.getElementById(id)
            this.copyTextToClipboard(copyText.value)
        },

        fallbackCopyTextToClipboard(text) {
            var textArea = document.createElement("textarea");
            textArea.value = text;

            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                console.log('Fallback: Copying text command was ' + msg);
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }

            document.body.removeChild(textArea);
        },

        copyTextToClipboard(text) {
            if (!navigator.clipboard) {
                this.fallbackCopyTextToClipboard(text);
                return;
            }
            navigator.clipboard.writeText(text).then(function () {
                console.log('Async: Copying to clipboard was successful!');
            }, function (err) {
                console.error('Async: Could not copy text: ', err);
                this.fallbackCopyTextToClipboard(text);
            });
        }
    },

    mounted() {
        this.handleResize();
        this.handleScroll();
        this.mounted = true;
    },

    created() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleResize);
    },

    destroyed() {
        window.removeEventListener('scroll');
        window.removeEventListener('resize');
    },

})
