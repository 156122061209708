<template>
    <div class="menu-container" :class="{ 'loaded': loaded, 'd-none': !loaded }" >
        <div class="mobile-nav-top pt-4 d-flex justify-content-center">

        </div>
        <div class="d-flex flex-column flex-grow-1 justify-content-center align-items-center p-4">
            <div v-for="(navitem, index) in mobileNavData" v-bind:key="index">
                <div class="pt-3 pb-2 d-flex justify-content-between" >
                    <a :href="navitem.url" class="mobile-nav-link" :target="navitem.target != '' ? navitem.target : '_self'" >
                        {{ decodeHtml(navitem.title) }}
                    </a>
                    <span class="nav-icon text-primary" v-if="navitem.children.length > 0" @click="toggleSubMenu(index)">
                        <i class="fa" :class="{
                            'fa-plus-circle': !navitem.subMenuOpen,
                            'fa-minus-circle': navitem.subMenuOpen
                            }" ></i>
                    </span>
                </div>
                <div class="dropdown-container my-2 px-2" v-if="navitem.subMenuOpen" >
                    <div v-for="(child, i) in navitem.children" v-bind:key="i" >
                        <a :href="child.url" :class="'p-2 d-block'" :target="child.target != '' ? child.target : '_self'" >{{ decodeHtml(child.title) }}</a>
                    </div>
                </div>
            </div>
        </div>
        <slot></slot>
    </div>
</template>
<script>
require('es6-promise').polyfill();
import axios from 'axios'

export default {
    props: {
        'menu': {
            type: String,
            default: ''
        }
    },

    data(){
        return {
            loaded: false,
            mobileNavData: [],
            endpoint: ''
        }
    },

    watch: {
        endpoint: function () {
            this.mobileNavData = [];
            this.getMenu();
        }
    },

    created() {
        this.endpoint = this.menu;
        this.getMenu();
    },

    methods: {

        decodeHtml(html) {
            var txt = document.createElement('textarea');
            txt.innerHTML = html;
            return txt.value;
        },

        getMenu() {
            let vm = this;

            axios.get("/wp-json/kerigansolutions/v1/navigation-menu?slug=" + this.endpoint)
            .then(response => {
                vm.mobileNavData = Object.keys(response.data).map((item) => {
                    response.data[item].subMenuOpen = false;
                    if(response.data[item].children.length > 0){
                        response.data[item].subMenuOpen = true;
                    }
                    return response.data[item]
                })
                vm.loaded = true;
            })

            
        },
        
        toggleSubMenu(navitem){
            this.mobileNavData[navitem].subMenuOpen = !this.mobileNavData[navitem].subMenuOpen;
        },

        closeMenu() {
            this.$emit('closemenu', this.menu);
        }
    }
}
</script>
