<template>
    <div>
        <select
            name="acreage"
            id="acreage-field"
            class="custom-select rounded-pill"
            v-model="selected"
        >
            <option value="">Any</option>
            <option value=".5" >1/2 or more</option>
            <option value="1" >1 or more</option>
            <option value="2" >2 or more</option>
            <option value="5" >5 or more</option>
            <option value="10" >10 or more</option>
        </select>
    </div>
</template>

<script>
    export default {
        props: {
            fieldValue: {
                type: String,
                default: ""
            }
        },

        data () {
            return {
                selected: this.fieldValue
            }
        },

        watch: {
            selected(val){
                this.$emit('updated', {
                    property: 'acreage',
                    value: val
                })
            }
        },
    }
</script>