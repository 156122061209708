<template>
    <div>
        <label class="sr-only" for="property-type-field">Proprty Type</label>
        <select
            name="property_type"
            id="property-type-field"
            v-model="selected"
            class="prop-type-input custom-select rounded-pill" >
            <option value="">{{ preview }}</option>
            <option value="House">Single Family Home</option>
            <option value="Land">Lots / Land</option>
            <option value="Multi">Multi-Family Home</option>
            <option value="Condo">Condo / Townhome</option>
            <option value="Commercial">Commercial</option>
            <option value="Manufactured">Manufactured</option>
            <option value="Farms">Farms / Agricultural</option>
        </select>
    </div>
</template>

<script>
    export default {
        props: {
            fieldValue: {
                type: String,
                default: ''
            },
            preview: {
                type: String,
                default: "Any"
            }
        },
        watch: {
            selected(val){
                this.$emit('updated', {
                    property: 'property_type',
                    value: val
                })
            }
        },
        data () {
            return {
                selected: 'Any'
            }
        },
        mounted() {
            this.selected = this.fieldValue
        }
    }
</script>
