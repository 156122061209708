<template>
    <button
        @click.prevent="handle(mlsNumber)"
        class="btn btn-secondary rounded-pill btn-block"
    >{{ text }}
    </button>
</template>
<script>
export default {
    props: {
        userId: {
            type: Number,
            required: true,
        },
        mlsNumber: {
            type: Number,
            default: 0,
        },
        isFavorite: {
            type: Number,
            default: 0,
        }
    },

    data() {
        return {
            text: ''
        }
    },

    created() {
        if(this.isFavorite == 0) {
            this.text = 'Save to Favorites'
        }

        if(this.isFavorite == 1) {
            this.text = 'Remove From Favorites'
        }
    },

    methods: {
        handle(mlsaccount) {
            let vm = this;

            fetch("/wp-json/kerigansolutions/v1/handlefavorite" +
            "?user_id=" + this.userId +
            "&mls_account=" + mlsaccount )
            .then(res => res.json())
            .then(response => {
                this.$emit('changed', { count: response.data.count })

                if(response.data.action == 'remove'){
                    this.text = 'Save to Favorites'
                }

                if(response.data.action == 'add'){
                    this.text = 'Remove From Favorites'
                }
            })
        },
    }
}
</script>
