<template>
  <on-click-outside :do="close">
    <div class="search-select rounded-pill" :class="{ 'is-active': isOpen }">
      <div class="d-flex">
        <button
          ref="button"
          @click="open"
          @keyup.enter="open"
          aria-label="press enter to to open smart search to find City, County, Subdivision, MLS# and more"
          type="button"
          class="search-select-input form-control rounded-pill py-2 text-left"
        >
            <span v-if="omni !== null && omni !==''">{{ toTitleCase(omni) }}</span>
            <span v-else class="search-select-placeholder">{{ preview }}</span>
        </button>
        <span
          v-if="omni !== ''"
          @click="omni = ''"
          class="btn btn-secondary cancel-button"
          role="link"
          aria-label="press enter to clear current smart search selection"
          tabindex="0"
        >
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div ref="dropdown" v-show="isOpen" class="search-select-dropdown">
        <input
          ref="search"
          class="form-control rounded-pill"
          name="omni"
          v-model="omni"
          @keydown.esc="close"
          autocomplete="off"
          @keyup.enter.prevent="closeAndSelect"
          aria-label="Type to query available searches. Press tab to navigate options, or escape to cancel."
        />
        <div ref="options" v-if="options.length > 0" class="search-select-options my-2">
            <div
                v-for="category in options"
                :key="category.text"
            >
                <div v-if="category.children.length > 0" >
                    <div class="search-select-category text-secondary font-weight-bold bg-light py-1 px-3" >
                        {{ category.text }}
                    </div>
                    <div
                        class="search-select-option py-1 px-3"
                        v-for="child in category.children"
                        :key="child"
                        @click="select(child)"
                        @keyup.enter="select(child)"
                        :aria-label="'press enter to select ' + child"
                        role="link"
                        tabindex="0"
                    >{{ toTitleCase(child) }}</div>
                </div>
            </div>
        </div>
        <div
            v-else-if="omni.length > 2" class="search-select-empty"
        >No results found for {{ omni }}</div>
        <a @click="isOpen = false" class="text-primary px-3">cancel</a>
      </div>
    </div>
  </on-click-outside>
</template>

<script>
import OnClickOutside from "./OnClickOutside.vue"

export default {
    components: {
        'on-click-outside': OnClickOutside
    },
    props: ['value','fieldValue','preview'],

    data() {
        return {
            isOpen: false,
            omni: "",
            baseUrl: "https://rets.kerigan.com/api/v2/smartfield",
            association: 'cpar',
            fields: 'city|area|subdivision|rets_listing_id|full_address|zip',
            omniTerms: [],
        }
    },

    mounted(){
        if(this.fieldValue !== '') {
            this.select(this.fieldValue);
        }
    },

    computed: {
        options () {
            if(this.omniTerms.length > 0){
                return this.filter(this.omni)
            } else {
                return []
            }
        }
    },

    methods: {
        getOptions (value){
            let vm = this

            fetch(vm.baseUrl + '?association=' + this.association + '&fields=' + this.fields)
                .then(res => res.json())
                .then(options => {
                    vm.omniTerms = options
                })
        },

        open() {
            this.isOpen = true
            this.getOptions()

            this.$nextTick(() => {
                this.focusInput()
            })
        },

        focusInput() {
            this.$refs.search.focus()
        },

        close() {
            if (!this.isOpen) return
            this.isOpen = false
        },

        closeAndSelect() {
      this.$emit("updated", {
          property: 'omni',
          value: this.omni
      })
      this.close()
    },

    clear() {
      this.omni = ""
      this.$emit("updated", {
          property: 'omni',
          value: ""
      })
      this.close()

      this.$nextTick(() => {
        this.$refs.button.focus()
      })
    },

    select(option) {
      this.omni = option
      this.$emit("updated", {
          property: 'omni',
          value: option
      })
      this.close()
    },

    toTitleCase(str) {
      if (!str) {
        return "";
      }
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },

    filter (text) {
		if(text.length < 3) { return [] }
		let options = []
		this.omniTerms.forEach(category => {
			options.push({
				text: category.text,
				children: category.children.filter(term => {
					return term != null && text != null && term.toLowerCase().includes(text.toLowerCase())
				})
			})
		})
		return options
    }
    }
};
</script>
<style scoped>
.search-select {
  position: relative;
}
.search-select-input {
  line-height: 1.2em;
}
.search-select-input:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.search-select-placeholder {
  color: #5669a9;
  font-weight: 600;
}
.search-select.is-active .search-select-input {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.search-select-dropdown {
  border-radius: 1.5rem;
  position: absolute;
  right: 0;
  left: 0;
  top: -2px;
  background-color: #fff;
  padding: 0.5rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 50;
  border: 1px solid #ddd;
}
.search-select-search {
  display: block;
  margin-bottom: 0.5rem;
  width: 100%;
  padding: 0.38rem 0.7rem;
  background-color: #fff;
  color: #2a2d2e;
  border-radius: 0;
  border: 1px solid #ddd;
}
.search-select-search:focus {
  outline: 0;
}
.search-select-options {
  list-style: none;
  padding: 0;
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 14rem;
}
.search-select-category {
  cursor: pointer;
  border-radius: 0.25rem;
  user-select: none;
}
.search-select-option {
  color:rgba(0, 0, 0, 0.8);
  cursor: pointer;
  border-radius: 0.25rem;
  user-select: none;
}
.search-select-option:hover {
  background-color: #3B398B;
  color: #fff;
}
.search-select-option.is-active,
.search-select-option.is-active:hover {
  background-color: #3B398B;
  color: #fff;
}
.search-select-empty {
  padding: 0.5rem 0.75rem;
  color: #b8c2cc;
}
.cancel-button {
  position: absolute;
  right: 3px;
  border-radius: 50%;
  height: 34px;
  width: 34px;
  top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  line-height: 1em;
  cursor: pointer;
}
</style>
