<template>
    <div class="num-favorite-properties text-accent">
        {{ num }}
    </div>
</template>
<script>
require("es6-promise").polyfill();
import axios from "axios";

export default {
    props: {
        userId: {
            type: Number,
            required: true,
        },
        count: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            num: 0,
        };
    },

    created() {
        this.num = this.count;
    },

    methods: {
        handle(data) {
           this.num = Number(data.count)
        },
    },
};
</script>