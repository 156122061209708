<template>
    <div>
        <form ref="propertysearchform" class="form" method="get" >
            <input type="hidden" name="q" value="search" >
            <input v-if="formData.sort" type="hidden" name="sort" :value="formData.sort" >

            <div class="row justify-content-center">
                <div class="col-12 col-lg-5 my-2" >
                    <label class="sr-only" for="omni-field">City, Subdivision, MLS# or Zip Code</label>
                    <smart-field
						v-bind:fieldValue="formData.omni"
						@updated="updateValue"
						preview="City, Subdivision, MLS# or Zip Code"
                        id="omni-field"
					></smart-field>
                </div>
                <div class="col-sm-6 col-lg-3 my-2" >
                    <label class="sr-only" for="proptype-field">Property Type</label>
                    <property-type
                        id="proptype-field"
                        :fieldValue="formData.property_type"
                        @updated="updateValue"
                        preview="Property Type"
                    >
                    </property-type>
                </div>

                <div class="col col-lg-4 my-2" >
                    <div class="row align-items-center">
                        <div class="col-12 col-md-6 col-lg" >
                            <button
                                @click="toggleAdvanced"
                                type="button"
                                class="btn btn-secondary btn-block rounded-pill"
                                >Advanced <i class="fa" :class="{'fa-times': advancedOpen, 'fa-bars': !advancedOpen }" ></i></button>
                        </div>

                        <div class="d-none d-md-block col-sm-6 col-lg" :class="{'col-12': advancedOpen, 'col-6': !advancedOpen }" >
                            <button type="submit" class="btn btn-dark btn-block rounded-pill">Search</button>
                        </div>
                    </div>
                </div>

                <div v-if="advancedOpen" class="col-12" >
                    <hr>
                    <div class="row" >
                        <div v-if="advancedOpen" class="col-6 col-lg-4 my-2">
                            <label for="min-price-field">Min Price</label>
                            <min-price-field
                                :fieldValue="formData.minPrice"
                                @updated="updateValue"
                            >
                            </min-price-field>
                        </div>
                        <div v-if="advancedOpen" class="col-6 col-lg-4 my-2">
                            <label for="max-price-field">Max Price</label>
                            <max-price-field
                                :fieldValue="formData.maxPrice"
                                @updated="updateValue"
                            ></max-price-field>
                        </div>
                        <div v-if="advancedOpen" class="col-6 col-lg-4 my-2">
                            <label for="sqft-field">Total Sqft</label>
                            <sqft-field
                                :fieldValue="formData.sqft"
                                @updated="updateValue"
                            ></sqft-field>
                        </div>


                        <div v-if="advancedOpen" class="col-6 col-lg-4 my-2">
                            <label for="bedrooms-field">Bedrooms</label>
                            <bedrooms-field
                                :fieldValue="formData.bedrooms"
                                @updated="updateValue"
                            ></bedrooms-field>
                        </div>
                        <div v-if="advancedOpen" class="col-6 col-lg-4 my-2">
                            <label for="bathrooms-field">Bathrooms</label>
                            <bathrooms-field
                                :fieldValue="formData.bathrooms"
                                @updated="updateValue"
                            ></bathrooms-field>
                        </div>
                        <div v-if="advancedOpen" class="col-6 col-lg-4 my-2">
                            <label for="acreage-field">Acreage</label>
                            <acreage-field
                                :fieldValue="formData.acreage"
                                @updated="updateValue"
                            ></acreage-field>
                        </div>


                        <div v-if="advancedOpen" class="col-lg-4 my-2">
                            <status-field
                                :fieldValue="formData.status"
                                @updated="updateValue"
                            ></status-field>
                        </div>
                        <div v-if="advancedOpen" class="col-lg-4 my-2">
                            <construction-field
                                :fieldValue="formData.construction"
                                @updated="updateValue"
                            ></construction-field>
                        </div>
                        <div v-if="advancedOpen" class="col-lg-4 my-2">
                            <features-field
                                :fieldValue="formData"
                                @updated="updateValue"
                            ></features-field>
                        </div>
                    </div>
                </div>
                <div v-else >
                    <input v-if="formData.minPrice != ''" type="hidden" name="minPrice" :value="formData.minPrice" >
                    <input v-if="formData.maxPrice != ''" type="hidden" name="maxPrice" :value="formData.maxPrice" >
                    <input v-if="formData.beds != ''" type="hidden" name="beds" :value="formData.beds" >
                    <input v-if="formData.baths != ''" type="hidden" name="baths" :value="formData.baths" >
                    <input v-if="formData.sqft_hc != ''" type="hidden" name="sqft" :value="formData.sqft_hc" >
                    <input v-if="formData.acreage != ''" type="hidden" name="acreage" :value="formData.acreage" >
                    <input type="hidden" name="construction[]" value="Updated/Remodeled" class="custom-control-input" v-if="construction.remodeled" >
                    <input type="hidden" name="construction[]" value="Under Construction" class="custom-control-input" v-if="construction.current" >
                    <input type="hidden" name="construction[]" value="To Be Built" class="custom-control-input" v-if="construction.future">
					<input type="hidden" name="construction[]" value="Fixer" class="custom-control-input" v-if="construction.fixer">
                    <input type="hidden" name="status[]" value="Active" class="custom-control-input" v-if="status.active">
                    <input type="hidden" name="status[]" value="Sold" class="custom-control-input" v-if="status.sold">
                    <input type="hidden" name="status[]" value="Under Contract" class="custom-control-input" v-if="status.undercontract">
                    <input type="hidden" name="status[]" value="Pending" class="custom-control-input" v-if="status.pending">
                </div>
                <div class="d-md-none my-2" :class="{'col-12': advancedOpen, 'col-6': !advancedOpen }" >
                    <button type="submit" class="btn btn-dark btn-block rounded-pill">Search</button>
                </div>
            </div>

            <input name="page" value="1" type="hidden" >
        </form>
    </div>
</template>

<script>
import SmartField from './fields/SmartField.vue'
import Acreage from './fields/Acreage.vue'
import TotalSqft from './fields/TotalSqft.vue'
import Bathrooms from './fields/Bathrooms.vue'
import Bedrooms from './fields/Bedrooms.vue'
import MaxPrice from './fields/MaxPrice.vue'
import MinPrice from './fields/MinPrice.vue'
import Status from './fields/Status.vue'
import PropertyType from './fields/PropertyType.vue'
import Construction from './fields/Construction.vue'
import Features from './fields/Features.vue'

    export default {

        props: {
            searchTerms: {
                type: Object,
                default: () => {}
            }
        },

        components: {
            'smart-field': SmartField,
            'property-type': PropertyType,
            'status-field': Status,
            'min-price-field': MinPrice,
            'max-price-field': MaxPrice,
            'bedrooms-field': Bedrooms,
            'bathrooms-field': Bathrooms,
            'acreage-field': Acreage,
            'sqft-field': TotalSqft,
            'construction-field': Construction,
            'features-field': Features
        },
        data(){
            return {
                advancedOpen: false,
                formData: {
                    omni: '',
                    property_type: '',
                    minPrice: '',
                    maxPrice: '',
                    beds: '',
                    baths: '',
                    sqft: '',
                    acreage: '',
                    status: [
                        'Active', 'Pending', 'Contingent'
                    ],
                    construction: [],
                    pool: false,
                    is_waterfront: false,
					is_waterview: false,
                    open_houses: false
                },
                construction: {
                    remodeled: false,
                    current: false,
                    future: false,
					fixer: false
                },
                status: {
                    active: true,
                    sold: false,
                    undercontract: true,
                    pending: true
                },
            }
        },

        created(){
            this.formData.omni = this.searchTerms.omni ?? this.formData.omni
            this.formData.property_type = this.searchTerms.property_type ?? this.formData.property_type
            this.formData.minPrice = this.searchTerms.minPrice ?? this.formData.minPrice
            this.formData.maxPrice = this.searchTerms.maxPrice ?? this.formData.maxPrice
            this.formData.beds = this.searchTerms.beds ?? this.formData.beds
            this.formData.baths = this.searchTerms.baths ?? this.formData.baths
            this.formData.acreage = this.searchTerms.acreage ?? this.formData.acreage
            this.formData.sqft = this.searchTerms.sqft ?? this.formData.sqft
            this.formData.construction = this.searchTerms.construction ?? this.formData.construction
            this.formData.status = this.searchTerms.status ?? this.formData.status
            this.formData.pool = this.searchTerms.pool ?? this.formData.pool
            this.formData.is_waterfront = this.searchTerms.is_waterfront ?? this.formData.is_waterfront
			this.formData.is_waterview = this.searchTerms.is_waterview ?? this.formData.is_waterview
            this.formData.open_houses = this.searchTerms.open_houses ?? this.formData.open_houses

            if(this.searchTerms.construction !== undefined){
                this.construction.remodeled = this.searchTerms.construction.includes('Updated/Remodeled')
                this.construction.current = this.searchTerms.construction.includes('Under Construction')
                this.construction.future = this.searchTerms.construction.includes('To Be Built')
				this.construction.fixer = this.searchTerms.construction.includes('Fixer')
            }

            if(this.searchTerms.status !== undefined){
                this.status.active = this.searchTerms.status.includes('Active')
                this.status.sold = this.searchTerms.status.includes('Closed')
                this.status.undercontract = this.searchTerms.status.includes('Contingent')
                this.status.pending = this.searchTerms.status.includes('Pending')
            }

        },
        methods: {
            updateValue(data){
                this.formData[data.property] = data.value
            },
            toggleAdvanced(event){
                if (event) event.preventDefault();
                this.advancedOpen = !this.advancedOpen;
            },
            toggleSearch(event){
                if (event) event.preventDefault();
                this.showSearch = !this.showSearch;
                this.advancedOpen = true;
            },
            toggleSort(event){
                if (event) event.preventDefault();
                this.showSort = !this.showSort;
            },
            submitForm(event){
                if(event){
                    event.preventDefault()
                }

                let vm = this
                let route = "?q=search"

                Object.keys(this.formData).forEach(key => {
                    if(vm.formData[key] != undefined && vm.formData[key] != '') {
                        if(Array.isArray(vm.formData[key])){
                            vm.formData[key].forEach(el => {
                                if(el != undefined && el != '' ){
                                    route += "&" + key + "[]=" + el
                                }
                            })
                        }else{
                            route += "&" + key + "=" + vm.formData[key]
                        }
                    }
                });

                window.location.href = route
            }
        }
    }
</script>
<style lang="scss" scoped>
.btn {
    font-size: .9rem;
}
</style>
