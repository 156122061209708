<template>
    <form class="form-inline" method="get" >
        <div class="input-group input-group-sm rounded-pill d-flex">
            <label for="sort-form" class="sr-only">Sort Results</label>
            <select name="sort"
                id="sort-form"
                v-model="selected"
                class="custom-select custom-select-sm rounded-left"
                style="width:auto;" >
                <option value="date_listed|desc">Date Listed </option>
                <option value="list_price|desc">Price - high to low</option>
                <option value="list_price|asc">Price - low to high</option>
                <option value="date_modified|desc">Date Modified </option>
            </select>
            <div class="input-group-append">
                <button type="submit" class="btn btn-sm btn-secondary rounded-right" @click.prevent="submitForm($event)" >Sort</button>
            </div>
        </div>
    </form>
</template>

<script>
    export default {
        props: {
            fieldValue: {
                type: String,
                default: 'date_listed|desc'
            },
            searchTerms: {
                type: Object,
                default: () => {}
            },
            anchor: {
				type: String,
				default: ''
			}
        },

        data () {
            return {
                selected: this.fieldValue,
                formData: {
                    city: '',
                    property_type: [],
                    minPrice: '',
                    maxPrice: '',
                    beds: '',
                    baths: '',
                    sqft: '',
                    acreage: '',
                    status: [],
                    construction: [],
                    pool: false,
                    is_waterfront: false,
					is_waterview: false,
                    open_houses: false,
					days: '',
                },
                construction: {
                    remodeled: false,
                    current: false,
                    future: false,
					fixer: false,
                },
                status: {
                    active: true,
                    undercontract: true,
                    pending: true,
					sold: false,
                },
            }
        },

        watch: {
            selected: {
                handler(val) {
                    this.formData.sort = val
                }
            }
        },

        mounted () {
            this.formData = this.searchTerms;

            if(this.searchTerms.sort !== null){
                this.selected = this.searchTerms.sort;
            }
        },

        methods: {
            submitForm(event){
                if(event){
                    event.preventDefault()
                }

                let vm = this
                let route = "?q=search"

                Object.keys(this.formData).forEach(key => {
                    if(vm.formData[key] != undefined && vm.formData[key] != '') {
                        if(Array.isArray(vm.formData[key])){
                            vm.formData[key].forEach(el => {
                                if(el != undefined && el != '' ){
                                    route += "&" + key + "[]=" + el
                                }
                            })
                        }else{
                            route += "&" + key + "=" + vm.formData[key]
                        }
                    }
                });

				route += this.anchor

                window.location.href = route
            }
        }
    }
</script>
