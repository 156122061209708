<template>
    <div>
        <select
            name="sqft"
            id="sqft-field"
            v-model="selected"
            class="custom-select rounded-pill">
            <option value="">Any</option>
            <option v-for="option in options" :value="option" :key="option" >{{ option.toLocaleString() }}</option>
        </select>
    </div>
</template>
<script>
    export default {
        props: {
            fieldValue: {
                type: [Number,String],
                default: ""
            }
        },

        data () {
            return {
                selected: this.fieldValue
            }
        },

        watch: {
            selected(val){
                this.$emit('updated', {
                    property: 'sqft',
                    value: val
                })
            }
        },

        computed: {
            options: function() {
                let options = [];
                for(let i = 0; i < 12; i++){
                    options[i] = ((i+4) * 200);
                }
                return options;
            }
        }
    }
</script>
