<template>
    <div>
        <div>
            <label>Construction Status</label>
        </div>
        <div class="d-block p-3 border rounded" >
            <label class="custom-control custom-checkbox">
                <input type="checkbox" name="construction[]" value="Updated/Remodeled" class="custom-control-input" v-model="construction.remodeled">
                <span class="custom-control-label">Updated / Remodeled</span>
            </label>
            <label class="custom-control custom-checkbox">
                <input type="checkbox" name="construction[]" value="Under Construction" class="custom-control-input" v-model="construction.current">
                <span class="custom-control-label">Under Construction</span>
            </label>
            <label class="custom-control custom-checkbox">
                <input type="checkbox" name="construction[]" value="To Be Built" class="custom-control-input" v-model="construction.future">
                <span class="custom-control-label">To Be Built</span>
            </label>
			<label class="custom-control custom-checkbox">
                <input type="checkbox" name="construction[]" value="Fixer" class="custom-control-input" v-model="construction.fixer">
                <span class="custom-control-label">Fixer Upper</span>
            </label>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
           fieldValue: {
                type: Array,
                default: () => []
            }
        },

        data () {
            return {
                construction: {
                    remodeled: false,
                    current: false,
                    future: false,
					fixer: false
                },
                value: []
            }
        },

        watch: {
            construction: {
                deep: true,
                handler(val){

                    this.value = []

                    if(val.remodeled == true){
                        this.value.push('Updated/Remodeled')
                    }
                    if(val.current == true){
                        this.value.push('Under Construction')
                    }
                    if(val.future == true){
                        this.value.push('To Be Built')
                    }
					if(val.fixer == true){
                        this.value.push('Fixer')
                    }

                    this.$emit('updated', {
                        property: 'construction',
                        value: this.value
                    })
                }
            }
        },

        mounted () {
            this.construction.remodeled = this.fieldValue.includes('Updated/Remodeled')
            this.construction.current = this.fieldValue.includes('Under Construction')
            this.construction.future = this.fieldValue.includes('To Be Built')
			this.construction.fixer = this.fieldValue.includes('Fixer')
        }
    }
</script>
