<template>
    <div>
        <select
            name="bedrooms"
            id="bedrooms-field"
            class="custom-select rounded-pill"
            v-model="selected"
        >
            <option value="">Any</option>
            <option v-for="option in options" :value="option" :key="option" >
                {{ option.toLocaleString() + '+' }}
            </option>
        </select>
    </div>
</template>
<script>
export default {
    props: {
        fieldValue: {
            type: [Number,String],
            default: ""
        }
    },

    data () {
        return {
            selected: this.fieldValue
        }
    },

    watch: {
        selected(val){
            this.$emit('updated', {
                property: 'bedrooms',
                value: val
            })
        }
    },

    computed: {
        options: function() {
            let options = [];
            for(let i = 0; i < 6; i++){
                options[i] = ((i+1))
            }
            return options;
        }
    }
}
</script>
