<template>
    <div class="slider-container bg-black">
        <div class="slider-overlay-content" ><slot></slot></div> 
        <div class="slider">
            <div class="slides d-lg-none" @mouseleave="unpauseSlide">
                <div
                    v-for="(slide, index) in sliderData" :key="index" 
                    class="slide" 
                    :class="{
                        'active': index == activeSlide
                    }"
                    v-lazy:background-image="slide.smartphone_photo.url"
                    :style="{ 
                        'background-position': slide.smartphone_image_alignment,
                        'background-size': slide.smartphone_image_fill_type,
                        'z-index': slide.index
                    }">
                    <div 
                        class="container p-0 d-flex h-100 text-white" 
                        :class="slide.smartphone_content_alignment"
                        ><div 
                            v-html="slide.content"
                            class="slide-content p-4 w-100 d-flex flex-column"
                            :class="slide.smartphone_content_justification"
                        ></div>
                    </div>
                    
                </div>
            </div> 
            <div class="slides d-none d-lg-flex" @mouseleave="unpauseSlide">
                <div
                    v-for="(slide, index) in sliderData" :key="index" 
                    class="slide" 
                    :class="{
                        'active': index == activeSlide
                    }"
                    v-lazy:background-image="slide.photo.url"
                    :style="{ 
                        'background-position': slide.image_alignment,
                        'background-size': slide.image_fill_type
                    }">
                    <div 
                        class="container p-0 d-flex h-100 text-white" 
                        :class="slide.content_alignment + ' ' + slide.content_justification"
                        ><div 
                            v-html="slide.content"
                            class="col-md-8 col-lg-6 slide-content py-4 py-lg-5"
                        ></div>
                    </div>
                </div>
            </div> 
        </div> 
    </div>
</template>

<script>
require('es6-promise').polyfill();
import axios from 'axios'

    export default {
        props: {
            category: {
                type: String,
                default: ''
            },
            limit: {
                type: Number,
                default: -1
            }
        },

        data() {
            return {
                sliderData: [],
                activeSlide: 0,
                paused: false
            }
        },

        created () {
            let category = (this.category != '' ? 'category=' + this.category : '');
            let limit = (this.limit != '' ? 'limit=' + this.limit : '');
            let request = '';

            if(category != '' || limit != -1){
                request = '?' + (category != '' ? category : '');
                if(category != '' && limit != -1){
                    request += '&' + limit;
                }else{
                    request += (limit != '' ? limit : '');
                }
            }

            axios.get("/wp-json/kerigansolutions/v1/sliders")
                .then(response => {
                    this.sliderData = response.data; 
                })
        },

        mounted () {
            setInterval(() => { if(this.paused === false){ this.nextSlide() } }, 6000);
        },

        methods: {
            nextSlide(){
                if(this.activeSlide === this.sliderData.length-1){
                    this.activeSlide = -1
                }
                this.activeSlide++
            },

            prevSlide(){
                this.activeSlide--
                if(this.activeSlide === -1){
                    this.activeSlide = this.sliderData.length-1
                }
            },

            clickNext(){
                this.nextSlide()
                this.pauseSlide()
            },

            clickPrev(){
                this.prevSlide()
                this.pauseSlide()
            },

            pauseSlide(){
                this.paused = true;
            },

            unpauseSlide(){
                this.paused = false;
            }

        }
    }
</script>
<style lang="scss" scoped>
.slider-container {
    position: relative;
    background-color: #ddd;
    z-index: -1;

    .slider {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 0;

        .slides {
            height: 100%;
            width: 100%;
            
            .slide {
                width:100%;
                height: 100%;
                top: 0; left: 0;
                opacity: 0;
                transition: opacity linear 2s;
                position: absolute;
                background-repeat: no-repeat;

                .slide-content {
                    position: relative;
                    z-index: inherit;
                    p {
                        line-height: 1.1em;
                    }
                }

                // .overlay {
                //     position: absolute;
                //     top: 0;
                //     height: 100%;
                //     width: 100%;
                //     background-color: rgba(0,0,0,.7);
                // }

                &.active {
                    opacity: 1;

                    .slide-content {
                        z-index: 15;
                    }

                    .slidelink {
                        z-index: 15;
                    }
                }
            }

        }
    }

    .slider-overlay-content {
        display: flex;
        align-items: flex-end;
        width: 100vw;
        position: absolute;
        z-index: 50;
        padding-bottom: 14vh;

        @media screen and (min-width: 993px){
            align-items: center;
            padding-bottom: 0;
        }
    }
}

.slider-container,
.slider-overlay-content {
    height: 100vh;

    @media screen and (min-width: 993px){
        height: 835px;
    }
}

</style>